const defaultConfig = {
  env: process.env.REACT_APP_ENV || 'development',
  imageSizes: [100, 200, 400, 'FULL'] // Replace full with a variable and use that variable everywhere
};

const developmentConfig = {
  qsApiEndPoint: 'https://api.dev.quicksell.co',
  iframeHost: 'https://appext.dev.quicksell.co/#/',
  searchEndPoint: 'https://search.dev.quicksell.co',
  s3TempCredentials:
    'https://us-central1-project-7804444097795271748.cloudfunctions.net/getTemporaryCredentials2',
  imageBucketName: 'quicksales-staging',
  imageBucketRegion: 'ap-south-1',
  imageUrlSource: {
    CLOUD_FRONT: 'https://daz11igyyg6m8.cloudfront.net',
    S3: 'https://quicksales-staging.s3.ap-south-1.amazonaws.com',
    CDN: 'https://daz11igyyg6m8.cloudfront.net',
    CDN_CATALOGUE: 'https://catalogue-cdn.quicksell.co/s/',
    sources: [
      'https://daz11igyyg6m8.cloudfront.net',
      'https://quicksales-staging.s3.ap-south-1.amazonaws.com'
    ]
  },
  videoBucketName: 'quicksales-original-videos-staging',
  videoCDNBucketName: 'quicksales-processed-videos-staging',
  videoBucketRegion: 'ap-south-1',
  videoUrlSource: {
    CLOUD_FRONT: 'https://daz11igyyg6m8.cloudfront.net',
    S3: 'https://s3.ap-south-1.amazonaws.com',
    CDN: 'https://daz11igyyg6m8.cloudfront.net',
    CDN_CATALOGUE: 'https://catalogue-cdn.quicksell.co/s/',
    sources: [
      'https://daz11igyyg6m8.cloudfront.net',
      'https://quicksales-staging.s3.ap-south-1.amazonaws.com'
    ]
  },
  mixpanelToken: '41d1908fcd671519b62332aaaea48ece',
  sentry: {
    dsn: '',
    dev: true,
    enabled: false,
    beforeSend(event, hint) {
      if (
        (event.message || '').includes('ResizeObserver') ||
        ((((event.exception || {}).values || [])[0] || {}).type || '').includes('ResizeObserver') ||
        (((event.exception || {}).values || [])[0] || {}).type ===
          'ResizeObserver loop limit exceeded'
      ) {
        return null;
      }
      return event;
    },
    ignoreErrors: [
      'ResizeObserver',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.'
    ]
  }
};

const productionConfig = {
  qsApiEndPoint: 'https://api.quicksell.co',
  iframeHost: 'https://appext.quicksell.co/#/',
  searchEndPoint: 'https://search.quicksell.co',
  s3TempCredentials: 'https://us-central1-sx-app-a04c1.cloudfunctions.net/getTemporaryCredentials2',
  imageBucketName: 'quicksales',
  imageBucketRegion: 'us-east-1',
  imageUrlSource: {
    CLOUD_FRONT: 'https://do9uy4stciz2v.cloudfront.net',
    S3: 'https://s3.amazonaws.com/quicksales',
    CDN: 'https://cdn.quicksell.co',
    sources: ['https://do9uy4stciz2v.cloudfront.net', 'https://s3.amazonaws.com/quicksales'],
    CDN_CATALOGUE: 'https://catalogue-cdn.quicksell.co/s/'
  },
  videoBucketName: 'quicksales-original-videos',
  videoCDNBucketName: 'quicksales-processed-videos',
  videoBucketRegion: 'ap-south-1',
  videoUrlSource: {
    CLOUD_FRONT: 'https://daz11igyyg6m8.cloudfront.net',
    S3: 'https://s3.ap-south-1.amazonaws.com',
    CDN: 'https://daz11igyyg6m8.cloudfront.net',
    CDN_CATALOGUE: 'https://catalogue-cdn.quicksell.co/s/',
    sources: [
      'https://daz11igyyg6m8.cloudfront.net',
      'https://quicksales-staging.s3.ap-south-1.amazonaws.com'
    ]
  },
  mixpanelToken: '4780b1d26e3d6ff2a6e0936133a6c145',
  sentry: {
    dsn: 'https://a186e5c1f9d6419ba1622cbd6aa10666@sentry.io/1246047',
    dev: false,
    enabled: false,
    beforeSend(event, hint) {
      if (
        (event.message || '').includes('ResizeObserver') ||
        ((((event.exception || {}).values || [])[0] || {}).type || '').includes('ResizeObserver') ||
        (((event.exception || {}).values || [])[0] || {}).type ===
          'ResizeObserver loop limit exceeded'
      ) {
        return null;
      }
      return event;
    },
    ignoreErrors: [
      'ResizeObserver',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.'
    ]
  }
};

const apiStagingConfig = {
  ...productionConfig,
  qsApiEndPoint: 'https://api-staging.quicksell.co',
  iframeHost: 'https://appext.dev.quicksell.co/#/'
};

const apiStaging2Config = {
  ...productionConfig,
  qsApiEndPoint: 'https://api-staging-2.quicksell.co',
  iframeHost: 'https://appext.dev.quicksell.co/#/'
};

let config;
if (defaultConfig.env === 'production') {
  config = { ...defaultConfig, ...productionConfig };
} else if (defaultConfig.env === 'api-staging') {
  config = { ...defaultConfig, ...apiStagingConfig };
} else if (defaultConfig.env === 'api-staging-2') {
  config = { ...defaultConfig, ...apiStaging2Config };
} else {
  config = { ...defaultConfig, ...developmentConfig };
}

export default config;
